<template>
  <div>
    <a-page-header :title="$route.query.id?'修改文章':'添加文章'" @back="() => $router.go(-1)" />

    <a-row>
      <a-col :span="12">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.post_title" />
          </a-form-item>
          <a-form-item label="关键词">
            <a-input v-model="addFromData.post_keywords" />
            <p>多关键词之间用英文逗号隔开。</p>
          </a-form-item>
          <a-form-item label="文章来源">
            <a-input v-model="addFromData.post_source" />
          </a-form-item>
          <a-form-item label="来源地址">
            <a-input v-model="addFromData.post_link" />
          </a-form-item>
          <a-form-item label="摘要">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="addFromData.post_excerpt"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="4">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="点击量">
            <a-input-number
              style="width:160px"
              v-model="addFromData.post_hits"
            />
          </a-form-item>
          <a-form-item label="点赞量">
            <a-input-number
              style="width:160px"
              v-model="addFromData.post_like"
            />
          </a-form-item>
          <a-form-item label="收藏量">
            <a-input-number
              style="width:160px"
              v-model="addFromData.post_favorites"
            />
          </a-form-item>
          <a-form-item label="排序">
            <a-input-number
              :min="0"
              style="width:160px"
              v-model="addFromData.list_order"
            />
          </a-form-item>
          <a-form-item label="发布时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              :locale="locale"
              v-model="addFromData.published_time"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-form-item label="是否置顶">
            <a-radio-group v-model="addFromData.is_top">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否推荐">
            <a-radio-group v-model="addFromData.recommended">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否精选">
            <a-radio-group v-model="addFromData.is_hot">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="是否横屏图">
            <a-radio-group v-model="addFromData.screen_img">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="文章分类">
            <a-tree-select
              v-model="addFromData.cat_ids"
              allowClear
              style="width: 200px"
              :tree-data="classify"
              :replaceFields="{ title: 'name', value: 'id' }"
              placeholder="分类(全部)"
            />
          </a-form-item>
          <a-form-item label="选择栏目">
            <GroupSelect
              @GroupSelect="GroupSelect"
              :selectId="addFromData.group_id"
              style="width:200px"
            ></GroupSelect>
          </a-form-item>
          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="16">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.post_content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="缩略图">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="多图">
            <a-upload
              name="file"
              @preview="handlePreview"
              :action="uploadImage"
              list-type="picture-card"
              :before-upload="beforeUpload"
              @change="handleChange2"
              :fileList="fileList"
            >
              <div v-if="fileList.length < 3">
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel2"
            >
              <img
                alt="example"
                style="max-width:450px;max-height:450px"
                :src="previewImage"
              />
            </a-modal>
          </a-form-item>
          <a-button type="primary" style="margin-left:150px" @click="handleOk">
            保存
          </a-button>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UE from "@/components/UE/UE.vue";
import {
  uploadImage,
  articleDataSave,
  articleDetail,
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  data() {
    return {
      locale,
      classify: this.$store.state.categoryShowList,
      addFromData: {
        id: "",
        post_title: "",
        group_id: undefined,
        post_content: "",
        post_keywords: "",
        post_source: "",
        post_link: "",
        post_excerpt: "",
        post_hits: "",
        post_favorites: "",
        post_like: "",
        list_order: "",
        published_time: undefined,
        is_top: 0,
        recommended: 0,
        is_hot: 0,
        cat_ids: undefined,
        thumbnail: "",
        images: [],
        images_name: [],
        company_id: undefined,
        company_name: "",
        screen_img:0
      },
      uploadImage,
      loading: false,
      imageUrl: "",
      fileList: [],
      imageList: [],
      previewVisible: false,
      previewImage: "",
    };
  },
  components: {
    UE,
  },
  computed: {
    categoryShowList() {
      return this.$store.state.categoryShowList;
    },
  },
  watch: {
    categoryShowList(val) {
      this.classify = val;
    },
    imageList() {
      let arr = [];
      let arr2 = [];
      if (this.imageList) {
        this.imageList.forEach((item) => {
          arr.push(item.uid);
          arr2.push(item.name);
        });
      }
      this.addFromData.images_name = arr2;
      this.addFromData.images = arr;
    },
  },
  created() {
    if (this.$route.query.id) {
      articleDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          if (res.data.photos_list.length > 0) {
            res.data.photos.name.forEach((item, index) => {
              this.fileList.push({
                uid: res.data.photos.url[index],
                name: item,
                url: res.data.photos_list[index].url,
                status: "done",
              });
              this.imageList.push({
                uid: res.data.photos.url[index],
                name: item,
                url: res.data.photos_list[index].url,
                status: "done",
              });
            });
          }
          this.addFromData = { ...res.data };
          if (res.data.company) {
            this.addFromData.company_name = res.data.company.company_name;
          } else {
            this.addFromData.company_id = undefined;
            this.addFromData.company_name = undefined;
          }
          if (res.data.published_time > 0) {
            this.addFromData.published_time = moment(
              Number(res.data.published_time + "000")
            ).format("YYYY-MM-DD HH:mm:ss");
          } else {
            this.addFromData.published_time = "";
          }

          // this.addFromData.published_time = moment(
          //   Number(res.data.published_time + "000")
          // ).format("YYYY-MM-DD HH:mm:ss");
          this.imageUrl = res.data.thumbnail_url;
        }
      });
    }
  },
  methods: {
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    GroupSelect(val) {
      this.addFromData.group_id = val;
    },
    getUeContent(val) {
      this.addFromData.post_content = val;
    },
    handleOk() {
      articleDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.go(-1);
          this.$message.success("保存成功");
        }
      });
    },
    handleCancel2() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.response.data.url;
      this.previewVisible = true;
    },
    handleChange2(info) {
      let { fileList } = info;
      this.fileList = [...fileList];
      if (info.file.status === "done") {
        this.imageList.push({
          uid: info.file.response.data.file_path,
          name: info.file.response.data.filename,
          url: info.file.response.data.url,
          status: "done",
        });
      }
      if (info.file.status === "removed") {
        if (info.file.response) {
          this.imageList.filter((item, index) => {
            if (item.uid == info.file.response.data.file_path) {
              this.imageList.splice(index, 1);
            }
          });
        } else {
          this.imageList.filter((item, index) => {
            if (item.uid == info.file.uid) {
              this.imageList.splice(index, 1);
            }
          });
        }
      }
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.thumbnail = info.file.response.data.file_path;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
